.hero {
    position: relative;
    height: 50vh;
}

.heroNav {
    width: 100%;
    position: absolute;
    z-index: 2;
}

.heroImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.heroText {
    position: absolute;
    background-color: rgba(15, 15, 68, 0.817);
    color: white;
    padding: 1rem;
    font-size: 1.1rem;
    font-family: sans-serif;
    font-weight: bold;
    top: 30%;
    left: 50%;
    translate: -50% 0;
    width: 90%;
    z-index: 1;
}

@media (width > 600px) {
    .heroText{
        top: 50%;
    }
}