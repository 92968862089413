*, *::after, *::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --dark-blue: rgb(13, 13, 41);;
  --blue: rgb(36, 41, 57);
  --light-blue: rgb(254, 255, 255);
  --accent: rgb(255, 184, 61);
}
