.nav {
    height: 8rem;
    display: flex;
    padding: 1rem ;
    background: linear-gradient(to bottom, var(--dark-blue), transparent ) ;
}

.navLinks {
    flex: 1;
    display: flex;
    align-items: center;
    position: relative;
}

.navLinks a {
    font-family: Secular One;
    flex: 1;
    text-decoration: none;
    font-size: clamp(1rem, 4vw, 1.2rem);
    text-align: center;
    color: white;
    text-shadow: 0 0 10px var(--dark-blue);
}

.navLinks a:hover {
    color: var(--accent);
}

.linkHighlight {
    position: absolute;
    top:80%;
    left: 0;
    width: 100%;
    height: .3rem;
}

.highlight {
    --width:  calc(100%/6);
    position: absolute;
    height: 100%;
    width: var(--width) ;
    background-color: aliceblue;
    transition: .3s ease-in-out;
}








.logoContainer {
    height: 100%;
    aspect-ratio: 1;
    cursor: pointer;
    position: relative;
    margin-right: 5rem;
    
}

.logoCircle{
    position: absolute;
    background-image: url('../../../public/media/ui/logoCircle.svg');
    background-repeat: no-repeat;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 100%;
    height: 100%;
    transition: .5s ease-in-out;
}

.logoContainer:hover .logoCircle {
    rotate: 360deg;
}

.logoText {
    background-image: url('../../../public/media/ui/logoText.svg');
    background-repeat: no-repeat;
    position: absolute;
    height: 60%;
    width: 200%;
    top: 50%;
    left: 50%;
    translate: -15% -50%;
}