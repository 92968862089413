.utilaje {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.utilajeBanner {
    width: 100%;
}

.utilaje ul {
    width: 100%;
}

.utilaje ul li {
    display: flex;
    justify-content: space-between;
}


@media (width > 600px) {
    .utilajeBanner{
        width: 90%;
    }
    
    .utilaje ul {
        width: 90%;
    }

}