.page {
    background-color: var(--light-blue);
    min-height: 50vh;
    padding: 1rem 2rem;
}

.text-imageCard {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1rem;
    align-items: center;
}



.textBlock {
    display: flex;
    flex: 3;
    flex-direction: column;
    gap: 1rem;
}

.textBlock p {
    text-indent: 2rem;
    font-size: 1.2rem;
    font-family: sans-serif;
}

.imgBlock {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.imgBlock img {
    width: 100%;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

@media (width > 600px) {

    .text-imageCard {
        flex-direction: row;
    }

    .text-imageCard:nth-child(even) {
        flex-direction: row-reverse;
    }
}

ul li {
    padding: .5rem 2rem;
    background-color: var(--blue);
    background: linear-gradient(to left, rgb(215, 223, 255), rgb(170, 170, 214));
    color: var(--dark-blue);
    font-size: 1.2rem;
    margin: .5rem 0;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    border-radius: 5px;
    list-style: none;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.403);
}

ul li:hover {
    outline: 2px solid var(--dark-blue);
}

.sectionTitle {
    width: 100%;
    border-bottom: 3px solid var(--dark-blue);
    color: var(--dark-blue);
    font-family: Secular One;
    margin: 1rem 0;
}