.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

.galleryCard {
    height: 20rem;
    aspect-ratio: 1;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.398);
}

.galleryCard img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}