.mobileNav {
    transition: .2s ease-in-out;
    overflow: hidden;
    z-index: 100;
}

.navHeader {
    height: 4rem;
    display: flex;
    padding: 1rem 2rem;
    align-items: center;
    gap: 1.5rem;
    position: relative;
    background: linear-gradient(to right, var(--dark-blue), transparent);
}

.navHeader img {
    height: 100%;
    scale: 1.5;
    transition: .3s ease-in-out;
}

.navHeader h2 {
    font-family: 'Secular One';
    color: white;
    flex: 1;
    text-shadow: 0 0 10px var(--dark-blue);
}

.toggleBtn {
    height: 100%;
    aspect-ratio: 1;
    position: relative;
}

.line {
    width: 80%;
    height: 15%;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    border-radius: 999px;
    transition: .3s ease-in-out;
}

.navContent {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.navContent a{
    padding: 1rem;
    width: 100%;
    text-align: center;
    text-decoration: none;
    font-size: 1.5rem;
    color: white;   
    position: relative; 
}

.navContent a:hover {
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.1) 50%, rgba(255, 255, 255, 0)80%);
}

.navContent a::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 1px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0.466) 50%, rgba(255, 255, 255, 0)80%);
}

.navContent a:last-child::after {
    content: "";
    background: none
}