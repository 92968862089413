
.sans {
    font-family: sans-serif;
    margin-left: 1rem;
    font-size: 1.3rem;
}

.contactInfo {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.contactInfo img {
    height: 20rem;
}

.infoLines {
    flex: 1;
    height: 100%;
}

.infoLine {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
}


.infoLine img {
    width: 2rem;
    height: auto;
}


@media (width > 600px) {
    .contactInfo {
        flex-direction: row;
    }
}